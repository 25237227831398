import { Link } from "gatsby"
import React from "react"

import kallo from "../images/kallo.svg"
import "./header.css";

const Header = () => (
  <header>
    <Link className="headerLink" to="/">
      <img className="headerImg" alt="" src={kallo}     
      style={{
        height: `200px`
      }}
      ></img>
      <h1>Karkkikammo</h1>
    </Link>
  </header>
)

export default Header
